<template>
  <form-buque />
</template>

<script>
import FormBuque from '@/pages/buque/tabs/form';

//Data
function data() {
  return {}
}

export default {
  name: 'add-buque',
  components: { FormBuque },
  data
}
</script>
<style scoped>

</style>